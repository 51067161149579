<template>
  <div id="au-list">
    <el-button type="text" @click="createFormVisible = true"
      >创建用户</el-button
    >

    <el-dialog
      title="请输入账户信息"
      :visible.sync="createFormVisible"
      width="30%"
    >
      <el-form :model="createForm" ref="createCargoForm">
        <el-form-item label="用户名">
          <el-input v-model="createForm.userName"></el-input>
        </el-form-item>
        <el-form-item label="昵称">
          <el-input v-model="createForm.nickName"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="createForm.pwd"></el-input>
        </el-form-item>
        <el-form-item label="权限">
          <el-checkbox-group v-model="createForm.permissions">
            <el-checkbox
              v-for="permission in page.permissions"
              :key="permission"
              :label="permission"
              >{{ page.permissionDesc[permission] }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="帐户类型">
          <el-select v-model="createForm.auType" placeholder="请选择">
            <el-option v-for="auType in page.autypeList" :key="auType" :label="page.autypeDesc[auType]" :value="auType">
            </el-option>
          </el-select>
        </el-form-item>
        
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="createFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="
            createAU();
            createFormVisible = false;
          "
          >提交
        </el-button>
      </div>
    </el-dialog>

    <el-divider></el-divider>

    <el-form :model="form" ref="form" inline>
      <el-form-item label="ID">
        <el-input v-model="form.id"></el-input>
      </el-form-item>
      <el-form-item label="用户名">
        <el-input v-model="form.userName"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="loadList">查询</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="page.list" border>
      <el-table-column label="ID" prop="id" width="60"> 
  
      </el-table-column>
      <el-table-column prop="userName" label="用户名" width="120">
      </el-table-column>
      <el-table-column label="昵称" width="120">
        <template v-slot="row">
          <template v-if="row.row.edit">
            <el-input v-model="editPwdForm.nickName" :placeholder="row.row.nickName "></el-input>
          </template>
          <template v-else>
            {{ row.row.nickName }}
          </template>
        </template>
      </el-table-column>
      <el-table-column label="身份" width="200"> 
        <template v-slot="row">
          <el-tag :type="getAUTypeColor(row.row.auType)" size="mini">{{ page.autypeDesc[row.row.auType]
            }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="是否可用" width="80">
        <template v-slot="row">
          <el-switch
            v-model="row.row.enable"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="editAU(row.row)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="权限">
        <template v-slot="row">
          <template v-if="row.row.edit">
            <el-checkbox-group v-model="row.row.permissions">
              <el-checkbox
                v-for="permission in page.permissions"
                :key="permission"
                :label="permission"
                >{{ page.permissionDesc[permission] }}</el-checkbox
              >
            </el-checkbox-group>
          </template>
          <template v-else>
            <template v-for="(permission, index) in row.row.permissions">
              <span :key="permission">{{
                page.permissionDesc[permission]
              }}</span>
              <el-divider
                :key="permission"
                v-if="index != row.row.permissions.length - 1"
                direction="vertical"
              ></el-divider>
            </template>
          </template>
        </template>
      </el-table-column>

    
      <el-table-column label="操作" width="400">
        <template v-slot="row">
          <el-button v-if="!row.row.edit" @click="row.row.edit = true"
            >编辑</el-button
          >
          <el-button
            v-if="row.row.edit"
            @click="
              editAU(row.row);
              row.row.edit = false;
            "
            >保存</el-button
          >
          <el-button v-if="row.row.edit" @click="row.row.edit = false"
            >取消</el-button
          >
          <el-divider direction="vertical"></el-divider>


          <el-popover placement="left" trigger="click">
            <el-form>
              <el-form-item label="新密码">
                <el-input v-model="editPwdForm.pwd"></el-input
              ></el-form-item>
              <el-form-item >
                <el-button @click="editPwd(row.row.id)">提交</el-button> </el-form-item>
            </el-form>

            <el-button slot="reference">修改登录密码</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  name: "AUList",
  data() {
    return {
      form: {
        id: null,
        userName: null,
      },

      createFormVisible: false,
      createForm: {
        userName: null,
        pwd: null,
        permissions: [],
        auType:null,
        nickName:null,
      },

      editPwdForm: {
        pwd: null,
        nickName:null,
        id: null,
      },

      page: {
        list: null,
        allShop: null,
        permissions: null,
        permissionDesc: null,
        autypeList:null,
        autypeDesc:null,
      },
    };
  },

  methods: {
    loadList() {
      this.axios.get("/sys/auList", { params: this.form }).then((resp) => {
        if (resp.data.success) {
          resp.data.data.list.forEach((element) => {
            element.edit = false;
          });
          this.page = resp.data.data;
        } else {
          this.$message.error(resp.data.msg);
        }
      });
    },

    createAU() {
      this.axios.post("/sys/createAU", this.createForm).then((resp) => {
        if (resp.data.success) {
          this.$message({
            message: "成功",
            type: "success",
          });
        } else {
          this.$message.error(resp.data.msg);
        }
      });
    },

    editAU(au) {
      this.axios.post("/sys/editAU", au).then((resp) => {
        if (resp.data.success) {
          this.$message({
            message: "成功",
            type: "success",
          });
        } else {
          this.$message.error(resp.data.msg);
        }
      });
    },

    editPwd(id) {
      this.editPwdForm.id = id;
      this.axios.post("/sys/editAU", this.editPwdForm).then((resp) => {
        if (resp.data.success) {
          this.$message({
            message: "成功",
            type: "success",
          });
        } else {
          this.$message.error(resp.data.msg);
        }
      });
    },

    getAUTypeColor(auType){
      var map = {
        'ROOT': 'danger',
        'AGENT': 'warning',
        'STUDIO_MANAGER': 'success',
        'CS': '',
      }
      var color = map[auType];
      if (color == null) {
        color = ''
      }

      return color;
    }
  },

  created() {
    this.loadList();
  },
};
</script>

<style scoped>
</style>